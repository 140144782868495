import * as THREE from 'three';
import Utils from './Utils';
import PropertyAnimation from './PropertyAnimation';

class Node {
	constructor(obj, atlasMap, labelsTexture, effectParameters, shader) {
		this.name = obj.id;
		this.atlasMap = atlasMap;
		this.pos = obj.pos;
		this.textureMetadata = obj.texture;
		this.labelsTexture = labelsTexture;

		this.animations = [];

		this.visible = false;
		this.visited = false;

		this.defaultParameters = {
			colors: {
			},
			uniforms: {
				time: {
					value: 0
				},
				globalAlpha: {
					value: 1
				}
			}
		};
		this.effectParameters = Object.assign({}, this.defaultParameters, effectParameters);

		this.textureDict = {
			baseTexture: {
				value: labelsTexture.texture
			}
		};

		this.setShader(shader);

		var mesh = this.buildMesh(this.name+'-default');
		this.setMesh(mesh);
	}

	buildMesh(key) {
		var tex = this.atlasMap[key];

		var geometry = Utils.createTexPlaneBufferGeometry(
			tex.pos.x,
			tex.pos.y,
			tex.size.x,
			tex.size.y,
			this.labelsTexture.dimensions[0],
			this.labelsTexture.dimensions[1]);

		var mesh = new THREE.Mesh(geometry, this.material);
		mesh.position.x = this.pos.x;
		mesh.position.y = this.pos.y;
		mesh.position.z = 50;

		mesh.userData['node'] = this;

		return mesh;
	}

	reloadShader() {
		this.shader.reloadCode();
		this.updateShader();
	}

	getShader() {
		return this.shader;
	}

	getShaderName() {
		return this.shaderName;
	}

	setUniform(id, value) {
		this.material.uniforms[id].value = value;
	}

	getUniform(id) {
		return this.material.uniforms[id];
	}

	update(mousePos) {
		// var w =  window.innerWidth;
		// var h =  window.innerHeight;
		// var mx = mousePos[0] / w;
		// var my = mousePos[1] / h;
		// this.mesh.position.x = this.px + (this.pos_mx_x/w) * mx + (this.pos_my_x/w) * my;
		// this.mesh.position.y = this.py - (this.pos_mx_y/h) * mx - (this.pos_my_y/h) * my;
		// this.mesh.position.z = this.pz;
		// this.mesh.scale.x = this.scale_x * (1+(this.scale_mx * mx + this.scale_my * my));
		// this.mesh.scale.y = this.scale_y * (1+(this.scale_mx * mx + this.scale_my * my));
	}

	setTexture(id, content, wrapS = THREE.MirroredRepeatWrapping, wrapT = THREE.MirroredRepeatWrapping) {
		var texture = content.texture;

		texture.wrapS = wrapS;
		texture.wrapT = wrapT;

		this.material.uniforms[id].value = texture;
	}

	updateColors() {
		this.shader.colorUniforms.forEach((colorName) => {
			var color = new THREE.Color(this.effectParameters.colors[colorName]);
			this.material.uniforms[colorName].value = new THREE.Vector4(color.r, color.g, color.b, 1);
		});
		this.material.uniformsNeedUpdate = true;
	}

	setMesh(mesh) {
		this.mesh = mesh;
	}

	getMesh(mesh) {
		return this.mesh;
	}

	setColor(id, hexValue) {
		this.effectParameters.colors[id] = hexValue;
		this.updateColors();
	}

	setShader(shader) {
		this.shader = shader;
		this.shaderName = shader.name;
		this.material = shader.materialShader.clone();

		for (var i in this.effectParameters.uniforms) {
			if (this.material.uniforms[i] === undefined) {
				this.material.uniforms[i] = {
					value: this.effectParameters.uniforms[i].value
				}
			}
		}

		var textureUniforms = this.shader.getTextureUniforms();
		for (var t in textureUniforms) {
			var texName = textureUniforms[t];
			if (this.textureDict[texName]) {
				this.material.uniforms[texName].value = this.textureDict[texName].value;
			}
		}

		var colorUniforms = this.shader.getColorUniforms();
		for (var c in colorUniforms) {
			var colorName = colorUniforms[c];
			this.effectParameters.colors[colorName] = '#000000';
		}
		this.updateColors();
	}

	animate(deltaTime) {
		this.material.uniforms['time'].value += deltaTime;

		var animations = [];
		for (var i in this.animations) {
			var animation = this.animations[i];
			animation.update(deltaTime);
			if (animation.hasCompleted() == false) {
				animations.push(animation);
			}
		}

		this.animations = animations;
	}

	animateUniform(id, targetValue, duration) {
		var uniform = this.getUniform(id);
		if (uniform) {
			var animation = new PropertyAnimation(uniform, 'value', duration, uniform.value, targetValue);
			this.animations.push(animation);
		}
	}
}

export default Node;