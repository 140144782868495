import * as THREE from 'three';
import { TGALoader } from 'three/examples/jsm/loaders/TGALoader.js';

class Utils {
	static loadImageRaw(file) {
		var p = new Promise((resolve) => {
			var loader = new TGALoader();
			var tex = loader.load(file.value, function() {
				resolve({
					name: file.name,
					imageData: file.value,
					texture: tex
				});
			});
		});

		return p;
	}


	static createTexPlaneBufferGeometry(x = 0, y = 0, width = 1.0, height = 1.0, tex_width = 1, tex_height = 1) {
		var vertices = new Float32Array( [
			-width/2.0,  height/2.0,  0.0,
			 width/2.0,  height/2.0,  0.0,
			 width/2.0, -height/2.0,  0.0,
			-width/2.0, -height/2.0,  0.0
		] );

		var uvs = new Float32Array( [
			// top left
			(x) / tex_width			, 1.0 - (y) / tex_height,
			// top right
			(x + width) / tex_width , 1.0 - (y) / tex_height,
			// bottom right
			(x + width) / tex_width , 1.0 - (y + height) / tex_height,
			// bottom left
			(x) / tex_width			, 1.0 - (y + height) / tex_height
		] );
		var indices = new Uint32Array( [0,3,1,1,3,2] );

		var bg = new THREE.BufferGeometry();
		bg.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
		bg.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
		bg.setIndex(new THREE.Uint8BufferAttribute(indices, 1));
		return bg;
	}

	static createPlaneBufferGeometry(x = 0, y = 0, width = 1.0, height = 1.0) {
		var vertices = new Float32Array( [
			0,  height/2.0,  0.0,
			width,  height/2.0,  0.0,
			width, -height/2.0,  0.0,
			0, -height/2.0,  0.0
		] );

		var uvs = new Float32Array( [
			0, 1,
			1, 1,
			1, 0,
			0, 0
		] );
		var indices = new Uint32Array( [0,3,1,1,3,2] );

		var bg = new THREE.BufferGeometry();
		bg.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
		bg.setAttribute('uv', new THREE.Float32BufferAttribute(uvs, 2));
		bg.setIndex(new THREE.Uint8BufferAttribute(indices, 1));
		return bg;
	}

	static distanceAB(a, b) {
		return Math.sqrt(Math.pow(b.x - a.x, 2.0) + Math.pow(b.y - a.y, 2.0));
	}


	static createFlatTexture(color, width = 1, height = 1, alpha = 1) {
		var size = width * height;
		var data = new Uint8Array( 4 * size );

		var r = Math.floor( color.r * 255 );
		var g = Math.floor( color.g * 255 );
		var b = Math.floor( color.b * 255 );
		var a = Math.floor( alpha * 255 );

		for ( var i = 0; i < size; i ++ ) {

			var stride = i * 3;

			data[ stride ] = r;
			data[ stride + 1 ] = g;
			data[ stride + 2 ] = b;
			data[ stride + 3 ] = a;

		}
		return {
			dimensions: [width, height],
			texture: new THREE.DataTexture( data, width, height, THREE.RGBAFormat )
		};
	}
}

export default Utils;