import * as THREE from 'three';
import Canvas from './Canvas';
import Shader from './Shader';
import Utils from './Utils';

var usePreRenderedTexture = true;

var knowledge = fetch('knowledge.json').then((response) => {
	return response.json();
});

var nodes = fetch('nodes.json').then((response) => {
	return response.json();
});

var description = fetch('description.json').then((response) => {
	return response.json();
});

var lineFX1 = Utils.loadImageRaw({
	name: 'lineFX1',
	value: 'fx/plasma.tga'
});
var edgeTex = Utils.loadImageRaw({
	name: 'edgeTex',
	value: 'tex_flat.tga'
});
var edgeMaskTex = Utils.loadImageRaw({
	name: 'edgeMaskTex',
	value: 'mask_flat.tga'
});
var flowTex = Utils.loadImageRaw({
	name: 'flowTex',
	value: 'fx/flow.tga'
});

var textures = Promise.all([lineFX1, edgeTex, edgeMaskTex, flowTex]);

var onload = new Promise((resolve, reject) => {
	window.onload = () => {
		resolve(true);
	}
});

Promise.all([knowledge, nodes, textures, description, onload]).then((result) => {
	start(result[0], result[1], result[2], result[3]);
});

var shaderPromises = [
	Shader.loadShaderPromise('basic', 'shaders/basic/vertex.glsl', 'shaders/basic/frag.glsl', 'shaders/basic/uniforms.json'),
	Shader.loadShaderPromise('addblend_layer4', 'shaders/addblend_layer4/vertex.glsl', 'shaders/addblend_layer4/frag.glsl', 'shaders/addblend_layer4/uniforms.json'),
	Shader.loadShaderPromise('alphablend_layer4', 'shaders/alphablend_layer4/vertex.glsl', 'shaders/alphablend_layer4/frag.glsl', 'shaders/alphablend_layer4/uniforms.json')
];

var start = (knowledge, nodes, textures, description) => {

	var data = getDataFromKnowledge(knowledge);
	var data = updateNodesPos(data, nodes);

	var result;
	var atlasMap;

	if (usePreRenderedTexture) {
		result = new Promise((resolve) => {
			var tex = new Promise((resolve) => {
				var loader = new THREE.TextureLoader();
				loader.load('texture.png', (texture) => {
					resolve({
						texture: texture,
						dimensions: [texture.image.width, texture.image.height]
					});
				});
			});
			var atlas = fetch('atlasMap.json').then((response) => {
				return response.json();
			});
			Promise.all([tex, atlas]).then((result) => {
				atlasMap = result[1];
				resolve(result[0]);
			});
		});
	}
	else {
		result = renderLabelsAtlas(data);
		atlasMap = result.atlasMap;
		result = result.texPromise;
	}

	result.then((tex) => {
		// var maskTex = renderLabelsMaskAtlas(data, tex);

		// textures.push({
		// 	name: 'maskTex',
		// 	texture: maskTex.texture
		// });

		Promise.all(shaderPromises).then(function(shaders) {
			var canvasDom = document.getElementById('stage');

			canvasDom.width = window.innerWidth;
			canvasDom.height = window.innerHeight;
			canvasDom.style.width = canvasDom.width+'px';
			canvasDom.style.height = canvasDom.height+'px';

			var canvas = new Canvas(canvasDom, shaders, tex, data, atlasMap, description, textures);
			canvas.start();
		});
	});
}

var updateNodesPos = (data, nodes) => {
	data.nodes.map((node) => {
		node['pos'] = nodes[node.id];
		node['pos'].x *= 12;
		node['pos'].y *= 12;
	});
	return data;
}

var getDataFromKnowledge = (result) => {
	var nodeIds = {};

	var data = {
		nodes: [],
		links: [],
		major: result.major
	};

	for (var i in result.links) {
		var edge = result.links[i];
		for (var j in edge) {
			var elem = edge[j];
			if (elem in nodeIds == false) {
				nodeIds[elem] = { id: elem };
				data.nodes.push(nodeIds[elem]);
			}
		}
		data.links.push({
			source: edge[0],
			target: edge[1]
		});
	}

	return data;
}

var renderNodeBackground = (node, ctx, pos, size, colors) => {
	var outerBorder = 3;
	var coloredBorder = 3;

	var px = pos.x + outerBorder + 2*coloredBorder;
	var py = pos.y + outerBorder + 2*coloredBorder;
	var sx = size.x - 2*outerBorder - 4*coloredBorder;
	var sy = size.y - 2*outerBorder - 4*coloredBorder;

	ctx.fillStyle = colors.background;
	ctx.fillRect(px, py, sx, sy);

	// bars
	// left
	ctx.fillStyle = colors.bar.vertical;
	ctx.fillRect(pos.x + outerBorder, pos.y + outerBorder, coloredBorder, size.y - 2*outerBorder);
	// right
	ctx.fillStyle = colors.bar.vertical;
	ctx.fillRect(pos.x + size.x - 2*outerBorder, pos.y + outerBorder, coloredBorder, size.y - 2*outerBorder);
	// bottom
	ctx.fillStyle = colors.bar.bottom;
	ctx.fillRect(pos.x + outerBorder, pos.y + size.y - 2*outerBorder, size.x - 2*outerBorder, coloredBorder);
	// top
	ctx.fillStyle = colors.bar.top;
	ctx.fillRect(pos.x + outerBorder, pos.y + outerBorder, size.x - 2*outerBorder, coloredBorder);

	// corners
	// top left
	ctx.fillStyle = colors.details.dark;
	ctx.fillRect(pos.x + outerBorder, pos.y + outerBorder, coloredBorder, coloredBorder);
	// top right
	ctx.fillRect(pos.x + size.x - 2*outerBorder, pos.y + outerBorder, coloredBorder, coloredBorder);

	// details
	// top left + (1,0)
	ctx.fillStyle = colors.details.bright;
	ctx.fillRect(pos.x + outerBorder + coloredBorder, pos.y + outerBorder, coloredBorder, coloredBorder);
	// top left + (0,1)
	ctx.fillStyle = colors.details.bright;
	ctx.fillRect(pos.x + outerBorder, pos.y + outerBorder + coloredBorder, coloredBorder, coloredBorder);
	// top left + (0,2)
	ctx.fillStyle = colors.details.lowBright;
	ctx.fillRect(pos.x + outerBorder, pos.y + outerBorder + coloredBorder + coloredBorder, coloredBorder, coloredBorder);

	// top right + (0,1)
	ctx.fillStyle = colors.details.bright;
	ctx.fillRect(pos.x + size.x - 2*outerBorder, pos.y + outerBorder + coloredBorder, coloredBorder, coloredBorder);
	// top right + (0,2)
	ctx.fillStyle = colors.details.lowBright;
	ctx.fillRect(pos.x + size.x - 2*outerBorder, pos.y + outerBorder + coloredBorder + coloredBorder, coloredBorder, coloredBorder);

	// bottom left + (1,0)
	ctx.fillStyle = colors.details.lowDark;
	ctx.fillRect(pos.x + outerBorder + coloredBorder, pos.y + size.y - 2*outerBorder, coloredBorder, coloredBorder);
	// bottom right - (1,0)
	ctx.fillStyle = colors.details.lowDark;
	ctx.fillRect(pos.x + size.x - outerBorder - coloredBorder - coloredBorder, pos.y + size.y - 2*outerBorder, coloredBorder, coloredBorder);

	var px = pos.x + outerBorder + coloredBorder + coloredBorder/2;
	var py = pos.y + outerBorder + coloredBorder + coloredBorder/2;
	var sx = size.x - 2*outerBorder - 3*coloredBorder;
	var sy = size.y - 2*outerBorder - 3*coloredBorder;

	ctx.beginPath();
	ctx.strokeStyle = "#000000";
	ctx.lineWidth = coloredBorder;
	ctx.rect(px, py, sx, sy);
	ctx.stroke();
}

var enforceCanvasBoundaries = (config, textWidth, maxHeight, cx, cy) => {
	if (cx + textWidth + config.padding.col + config.margin.right + 2*config.padding.row > config.canvas.width) {
		cx = config.canvas.margin.left + config.margin.left;
		cy += maxHeight + config.padding.row + config.margin.bottom + config.margin.top;
		maxHeight = 0;
	}
	return {
		maxHeight: maxHeight,
		cx: cx,
		cy: cy
	}
}

var renderTexturesForNode = (node, config, ctx, atlasMap, cx, cy) => {
	var maxHeight = 0;

	var text = ctx.measureText(node.id);
	var ascent = text.actualBoundingBoxAscent || 15;
	var descent = text.actualBoundingBoxDescent || 0;
	var fontHeight = ascent + descent || config.font.size;

	var promiseList = [];

	if (fontHeight > maxHeight) {
		maxHeight = fontHeight;
	}

	var result = enforceCanvasBoundaries(config, text.width, maxHeight, cx, cy);
	cx = result.cx;
	cy = result.cy;
	maxHeight = result.maxHeight;

	var extraBottomMargin = 0;
	if (descent == 0) {
		extraBottomMargin = 6;
	}

	var size = {
		x: config.margin.left + Math.ceil(text.width) + config.margin.right,
		y: config.margin.top + Math.ceil(fontHeight) + config.margin.bottom + extraBottomMargin
	};

	// render unselected
	var pos = {
		x: Math.ceil(cx - config.margin.left),
		y: Math.ceil(cy - ascent - config.margin.top)
	};

	atlasMap[node.id + '-default'] = {
		pos: pos,
		size: size
	};

	var colors = {
		background: "#333",
		bar: {
			vertical: "#273448",
			bottom: "#202135",
			top: "#89a2aa"
		},
		corners: {
			bottom: "#03d954",
		},
		details: {
			bright: "#89a2aa",
			dark: "#5a7383",
			lowBright: "#5a7383",
			lowDark: "#202135"
		}
	};

	var textColors = ["#dee3e6", "#8ca1aa"];

	if (node.id == "gbuzogany") {
		colors.background = "#ccc";
		// textColors = ["#028ad9", "#0260a3"];
	}
	renderNodeBackground(node, ctx, pos, size, colors);

	var imgData = renderStylizedText(config, node, size.x, size.y, textColors);
	var p = loadImageAndRender(imgData, ctx, pos.x + 6, pos.y);
	promiseList.push(p);

	cx += Math.ceil(text.width) + config.margin.right + config.padding.col + config.margin.left;

	var result = enforceCanvasBoundaries(config, text.width, maxHeight, cx, cy);
	cx = result.cx;
	cy = result.cy;
	maxHeight = result.maxHeight;

	// render selected
	var pos = {
		x: Math.ceil(cx - config.margin.left),
		y: Math.ceil(cy - ascent - config.margin.top)
	};

	atlasMap[node.id + '-selected'] = {
		pos: pos,
		size: size
	};

	// var colors = {
	// 	background: "#000000",
	// 	bar: {
	// 		vertical: "#005835",
	// 		bottom: "#003825",
	// 		top: "#02a449"
	// 	},
	// 	edge: {
	// 		bottom: "#03d954",
	// 		top: "#02a449",
	// 	},
	// 	details: {
	// 		bright: "#03d954",
	// 		dark: "#003825",
	// 		lowBright: "#02a449",
	// 		lowDark: "#005835"
	// 	}
	// };

	var colors = {
		background: "#ccc",
		bar: {
			vertical: "#002559",
			bottom: "#001438",
			top: "#0261a4"
		},
		edge: {
			bottom: "#03d954",
			top: "#02a449",
		},
		details: {
			bright: "#028ad9",
			dark: "#001438",
			lowBright: "#0260a3",
			lowDark: "#002559"
		}
	};

	var textColors = ["#028ad9", "#0260a3"];

	if (node.id == "gbuzogany") {
		colors.background = "#fff";
		// textColors = ["#dee3e6", "#8ca1aa"];
	}
	renderNodeBackground(node, ctx, pos, size, colors);

	var imgData = renderStylizedText(config, node, size.x, size.y, textColors);
	var p = loadImageAndRender(imgData, ctx, pos.x + 6, pos.y);
	promiseList.push(p);

	cx += Math.ceil(text.width) + config.margin.right + config.padding.col + config.margin.left;

	//
	return {
		renderPromises: promiseList,
		cx: cx,
		cy: cy
	}
};

var renderStylizedText = (config, node, sx, sy, colors) => {
	var canvas = document.createElement('canvas');

	canvas.width = sx;
	canvas.height = sy;
	canvas.style.width = sx+'px';
	canvas.style.height = sy+'px';

	var ctx = canvas.getContext('2d');
	ctx.imageSmoothingEnabled = false;

	ctx.clearRect(0, 0, sx, sy);

	ctx.font = config.font.size+'px '+config.font.face;
	var text = ctx.measureText(node.id);
	var ascent = text.actualBoundingBoxAscent || 15;
	var descent = text.actualBoundingBoxDescent || 0;
	var fontHeight = ascent + descent || config.font.size;

	// console.log(node.id, ascent, descent);

	var cx = config.margin.left - 4;
	var cy = ascent + config.margin.top;

	ctx.strokeStyle = "black";
	ctx.lineWidth = 8;
	var middleReflection = 4;
	ctx.fillStyle = "white";
	ctx.fillText(node.id, parseInt(cx), parseInt(cy));

	ctx.globalCompositeOperation = "source-atop";
	ctx.fillStyle = colors[0];
	ctx.fillRect(0, 0, sx, sy);
	ctx.fillStyle = colors[1];
	ctx.fillRect(0, config.margin.top + fontHeight/2-middleReflection, sx, fontHeight - 2*(fontHeight/2-middleReflection));

	ctx.globalCompositeOperation = "destination-over";

	ctx.lineWidth = 8;
	// ctx.font = (config.font.size+8)+'px '+config.font.face;
	// ctx.fillStyle = "black";
	ctx.strokeText(node.id, parseInt(cx), parseInt(cy));
	return canvas.toDataURL('image/png');
}

var renderLabelsAtlas = (knowledge) => {

	var config = {
		font: {
			size: 40,
			face: '"04b03"'
		},
		canvas: {
			width: 4096,
			height: 4096,
			margin: {
				top: 1,
				left: 1
			}
		},
		padding: {
			row: 10,
			col: 5
		},
		margin: {
			top: 25,
			bottom: 20,
			left: 25,
			right: 25
		}
	};

	var canvas = document.createElement('canvas');
	canvas.width = config.canvas.width;
	canvas.height = config.canvas.height;
	canvas.style.width = config.canvas.width+'px';
	canvas.style.height = config.canvas.height+'px';
	canvas.style.imageRendering = 'crisp-edges';

	var ctx = canvas.getContext('2d');
	ctx.imageSmoothingEnabled = false;
	ctx.font = config.font.size+'px '+config.font.face;
	ctx.clearRect(0, 0, canvas.width, canvas.height);

	var cx = config.canvas.margin.left + config.margin.left;
	var cy = config.canvas.margin.top + config.font.size + config.margin.top;

	var imgPromises = [];

	var atlasMap = {};

	for (var i in knowledge.nodes) {
		var node = knowledge.nodes[i];

		var result = renderTexturesForNode(node, config, ctx, atlasMap, cx, cy);
		cx = result.cx;
		cy = result.cy;

		Array.prototype.push.apply(imgPromises, result.renderPromises);
	}

	var p = new Promise((resolve) => {
		Promise.all(imgPromises).then((result) => {
			var tex = new THREE.CanvasTexture(canvas);
			resolve({
				texture: tex,
				dimensions: [config.canvas.width, config.canvas.height]
			});
		});
	});

	document.body.appendChild(canvas);

	if (!usePreRenderedTexture && 0) {
		var jsonData = JSON.stringify(atlasMap);
		var a = document.createElement("a");
		var file = new Blob([jsonData], {type: 'text/JSON'});
		a.href = URL.createObjectURL(file);
		a.download = 'atlasMap.json';
		a.click();
	}

	return {
		atlasMap: atlasMap,
		texPromise: p
	};
}

var loadImageAndRender = (imgData, ctx, px, py) => {
	return new Promise((resolve) => {
		const img = new Image();
		img.src = imgData;
		img.onload = () => {
			ctx.drawImage(img, px, py);
			resolve(img);
		}
	});
}

var renderLabelsMaskAtlas = (knowledge, sourceTex) => {

	var config = {
		ratio: 1
	};

	var canvas = document.createElement('canvas');
	canvas.width = sourceTex.dimensions[0]/config.ratio;
	canvas.height = sourceTex.dimensions[1]/config.ratio;
	canvas.style.width = canvas.width+'px';
	canvas.style.height = canvas.height+'px';

	var ctx = canvas.getContext('2d');
	ctx.fillStyle = "#000000";
	ctx.strokeStyle = "#ffffff";
	ctx.clearRect(0, 0, canvas.width, canvas.height);

	var blurRadius = 8;

	for (var i in knowledge.nodes) {
		var node = knowledge.nodes[i];


		// ctx.fillStyle = "#ff0000";
	 //    ctx.filter = 'blur('+blurRadius+'px)';
		ctx.fillStyle = "#00ff00";
		ctx.fillRect(
			(node.texture.pos.x) / config.ratio,
			(node.texture.pos.y) / config.ratio,
			(node.texture.size.x) / config.ratio,
			(node.texture.size.y) / config.ratio
		);

		ctx.fillStyle = "#ff0000";
	    // ctx.filter = "blur(0px)";  // "feather"
		ctx.fillRect(
			node.texture.text.pos.x / config.ratio,
			node.texture.text.pos.y / config.ratio,
			node.texture.text.size.x / config.ratio,
			node.texture.text.size.y / config.ratio
		);

		// ctx.beginPath();
		// ctx.rect(
		// 	(node.texture.pos.x+radius) / config.ratio,
		// 	(node.texture.pos.y+radius) / config.ratio,
		// 	(node.texture.size.x-2*radius) / config.ratio,
		// 	(node.texture.size.y-2*radius) / config.ratio
		// );
		// ctx.stroke();
	}

	document.body.appendChild(canvas);
	var tex = new THREE.CanvasTexture(canvas);

	return {
		texture: tex,
		dimensions: [canvas.width, canvas.height]
	};
};

