class PropertyAnimation {
	constructor(targetObject, targetProperty, duration, initialValue, finalValue, debug = false) {
		this.targetObject = targetObject;
		this.targetProperty = targetProperty;
		this.duration = duration;
		this.initialValue = initialValue;
		this.finalValue = finalValue;
		this.debug = debug;

		this.delta = finalValue - initialValue;
		this.totalTime = 0;
	}

	update(deltaTime) {
		this.totalTime += deltaTime;
		if (this.totalTime > this.duration) {
			this.totalTime = this.duration;
		}
		if (this.debug) {
			console.log(this.totalTime, this.duration);
		}
		this.targetObject[this.targetProperty] = this.initialValue + this.delta * (this.totalTime / this.duration);
	}

	hasCompleted() {
		return this.totalTime == this.duration;
	}
}

export default PropertyAnimation;
