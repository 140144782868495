import * as THREE from 'three';

class Shader {
	constructor(properties) {

		this.name = properties.name;

		this.textureUniforms = [];
		this.vectorUniforms = [];
		this.colorUniforms = [];

		this.uniformsDescriptor = properties.uniformsDescriptor;

		this.vertexPath = properties.vertex.path;
		this.fragmentPath = properties.fragment.path;

		this.materialShader = new THREE.RawShaderMaterial( {
			vertexShader: properties.vertex.content,
			fragmentShader: properties.fragment.content,
			uniforms: this.parseUniformsDescriptor(properties.uniformsDescriptor),
			transparent: true
		});
	}

	reloadCode() {
		var vertex = fetch(this.vertexPath).then(response => {
			return response.text();
		});

		var frag = fetch(this.fragmentPath).then(response => {
			return response.text();
		});

		Promise.all([vertex, frag]).then(function (result) {
			this.materialShader.vertexShader = result[0];
			this.materialShader.fragmentShader = result[1];
			this.materialShader.needUpdate = true;
		}.bind(this));
	}

	getTextureUniforms() {
		return this.textureUniforms;
	}

	getColorUniforms() {
		return this.colorUniforms;
	}

	getVectorUniforms() {
		return this.vectorUniforms;
	}

	getUniformsDescriptor() {
		return this.uniformsDescriptor;
	}

	parseUniformsDescriptor(uniformsDescriptor) {
		var uniforms = {};
		for (var u in uniformsDescriptor) {
			if (u === 'type') {
				continue;
			}
			var uniform = uniformsDescriptor[u];
			if (uniform['type'] !== undefined) {
				if (uniform['type'] === 'group') {
					var groupUniforms = this.parseUniformsDescriptor(uniform);
					for (var i in groupUniforms) {
						uniforms[i] = groupUniforms[i];
					}
				}
				else {
					if (uniform['isColor'] && uniform['isColor'] === true) {
						this.colorUniforms.push(u);
					}
					if (uniform['type'] === 'vec4') {
						this.vectorUniforms.push(u);
						uniform = {
							type: THREE.Vector4,
							value: new THREE.Vector4(uniform['value'][0], uniform['value'][1], uniform['value'][2], uniform['value'][3])
						};
					}
					else if (uniform['type'] === 't') {
						this.textureUniforms.push(u);
					}
					uniforms[u] = uniform;
				}
			}
			else {
				uniforms[u] = uniform;
			}
		}
		return uniforms;
	}

	static loadShaderPromise(name, vertexPath, fragmentPath, uniformsPath) {
		var vertex = fetch(vertexPath).then(response => {
			return response.text();
		});

		var frag = fetch(fragmentPath).then(response => {
			return response.text();
		});

		var uniforms = fetch(uniformsPath).then(response => {
			return response.json();
		});

		var p = new Promise((resolve) => {
			Promise.all([vertex, frag, uniforms]).then(function (result) {
				var properties = {
					name: name,
					vertex: {
						path: vertexPath,
						content: result[0]
					},
					fragment: {
						path: fragmentPath,
						content: result[1]
					},
					uniformsDescriptor: result[2]
				}

				resolve(new Shader(properties));
			});
		});

		return p;
	}
}

export default Shader;