import * as THREE from 'three';
import Shader from './Shader'
import Node from './Node'
import Edge from './Edge'
import Utils from './Utils';
import { MapControls, OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';
import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';

class Canvas {

	constructor(canvas, shaders, labelsTexture, data, atlasMap, description, textures) {
		this.lastTimestamp = 0;
		this.shaders = {};
		this.nodes = {};
		this.description = description;
		this.atlasMap = atlasMap;
		this.edges = [];
		this.meshes = [];
		this.canvas = canvas;
		this.labelsTexture = labelsTexture;
		this.data = data;
		this.scene = new THREE.Scene();
		this.mouse = new THREE.Vector2()
		this.raycaster = new THREE.Raycaster();
		this.camera = this.setupCamera(canvas.width, canvas.height);
		this.renderer = this.setupRenderer(canvas, canvas.width, canvas.height);
		this.controls = this.setupControls(this.camera, this.renderer);
		this.startPos = {x: 0, y: 0};

		var closeDescription = document.querySelector("#details > .close > a");
		closeDescription.addEventListener('click', this.onCloseDescriptionClick.bind(this), false);

		var closeMail = document.querySelector("#mail > .close > a");
		closeMail.addEventListener('click', this.onCloseMailClick.bind(this), false);

		var openMail = document.querySelector("#mailbutton > a");
		openMail.addEventListener('click', this.onOpenMailClick.bind(this), false);

		this.selection = undefined;

		this.textures = this.initTextures(textures);

		shaders.forEach((shader) => {
			this.shaders[shader.name] = shader;
		});

		var result;

		result = this.processNodes(this.data.nodes, this.atlasMap, this.labelsTexture, this.camera, this.controls, this.shaders['alphablend_layer4']);
		this.nodes = result.nodes;
		this.meshes = result.meshes;
		this.addMeshesToScene(result.meshes, this.scene);

		// result = this.processEdges(this.data, this.nodes, this.shaders['alphablend_layer4']);
		// this.addMeshesToScene(result.meshes, this.scene);

		this.graph = this.buildGraph(this.data, this.nodes);

		canvas.parentNode.appendChild(this.renderer.domElement);
		// document.body.appendChild(VRButton.createButton(this.renderer));

		this.renderer.domElement.addEventListener('click', this.onClick.bind(this), false);
		this.renderer.domElement.addEventListener('mousemove', this.onMouseMove.bind(this));
		this.renderer.domElement.addEventListener('touchstart', this.onTouchStart.bind(this), false);
		this.renderer.domElement.addEventListener('touchend', this.onTouchEnd.bind(this), false);

		window.addEventListener('resize', this.onWindowResize.bind(this), false);
		window.addEventListener('orientationchange', this.onOrientationChange.bind(this), false);
	}

	initTextures(textures) {
		var result = {};

		for (var i in textures) {
			var obj = textures[i];
			result[obj.name] = obj.texture;

			obj.texture.wrapS = THREE.MirroredRepeatWrapping;
			obj.texture.wrapT = THREE.MirroredRepeatWrapping;

			obj.texture.magFilter = THREE.NearestFilter;
			obj.texture.minFilter = THREE.NearestFilter;
		}

		return result;
	}

	addMeshesToScene(meshes, scene) {
		meshes.map((mesh) => {
			scene.add(mesh);
		});
	}

	removeMeshFromScene(mesh, scene) {
		scene.remove(mesh);
	}

	setupControls(camera, renderer) {
		var controls = new MapControls(camera, renderer.domElement);
		// var controls = new OrbitControls( this.camera, this.renderer.domElement );

		// controls.enableDamping = true;
		// controls.dampingFactor = 0.05;

		controls.enableRotate = false;
		// console.log(controls);
		// controls.zoomSpeed = 0.5;

		controls.mouseButtons.MIDDLE = THREE.MOUSE.PAN;

		if (camera.type == 'PerspectiveCamera') {
			controls.minDistance = 700;
			controls.maxDistance = 2000;
		}
		else if (camera.type == 'OrthographicCamera') {
			controls.minZoom = 0.18;
			controls.maxZoom = 1.5;
		}

		return controls;
	}

	setupRenderer(canvas, width, height) {
		var renderer = new THREE.WebGLRenderer({
			canvas: canvas,
			premultipliedAlpha: false
		});
		renderer.setPixelRatio(window.devicePixelRatio);
		renderer.setSize(width, height);
		renderer.setClearColor(0x000000, 0);
		renderer.xr.enabled = true;
		// renderer.logarithmicDepthBuffer = true;

		return renderer;
	}

	setupCamera(width, height) {
		// var camera = new THREE.OrthographicCamera(-width, width, -height, height, 0.1, 20000);
		var camera = new THREE.PerspectiveCamera(60, width / height, 1, 20000);
		camera.position.set(0, 0, 1200);
		camera.up = new THREE.Vector3(0, 0, 1);

		return camera;
	}

	buildGraph(data, nodes) {
		var graph = {};

		for (var i in data.links) {
			var link = data.links[i];
			if (graph[link.source] === undefined) {
				graph[link.source] = {};
			}
			graph[link.source][link.target] = {
				node: nodes[link.target],
				traversed: false
			};
			if (graph[link.target] === undefined) {
				graph[link.target] = {};
			}
			graph[link.target][link.source] = {
				node: nodes[link.source],
				traversed: false
			};
		}
		return graph;
	}

	processNodes(nodes, atlasMap, labelsTexture, camera, controls, shader) {

		var result = {
			meshes: [],
			nodes: {}
		}

		for (var i in nodes) {
			var node = nodes[i];

			if (i == 0) {
				var px = node.pos.x;
				var py = node.pos.y;
				camera.translateX(px);
				camera.translateY(py);
				controls.target = new THREE.Vector3(px, py, 0);
			}

			var inst = new Node(
				node,
				atlasMap,
				labelsTexture,
				{},
				shader
			);

			inst.setColor('baseColor', '#555555');

			if (i != 0) {
				inst.setUniform('lineFade', 0.00);
			}
			else {
				inst.visible = true;
			}

			var mesh = inst.getMesh();
			result.meshes.push(mesh);
			result.nodes[node.id] = inst;
		}

		return result;
	}

	processEdges(source, graph, shader) {
		var result = {
			meshes: [],
			edges: []
		}

		var links = graph[source.name];

		for (var i in links) {
			var link = links[i];
			if (link.traversed == false) {
				var target = link.node;
				link.traversed = true;
				graph[target.name][source.name].traversed = true;

				var blackTexture = Utils.createFlatTexture(new THREE.Color('#000000'), 1, 1, 0.0).texture;
				var whiteTexture = Utils.createFlatTexture(new THREE.Color('#ffffff')).texture;
				var pos = source.pos;

				var edge = new Edge(
					pos,
					source,
					target,
					{
						baseTexture: {
							// value: this.textures['edgeTex']
							value: blackTexture
						},
						maskTex: {
							value: this.textures['edgeMaskTex']
						},
						FX1FlowTexture: {
							value: this.textures['flowTex']
						},
						FX1Texture: {
							value: this.textures['lineFX1']
						}
					},
					{},
					shader
				);

				var distance = Utils.distanceAB(source.pos, target.pos);
				edge.setUniform('lineFade', 0.0);
				edge.setUniform('FX1Intensity', 15);
				edge.setUniform('FX1Invert', 0);
				edge.setUniform('FX1ScrollRotateFlow', 2.0);
				edge.setUniform('FX1DistortionAmount', 0);
				edge.setUniform('FX1PulseAmount', 0.3);
				edge.setUniform('FX1PulseRate', 200);
				edge.setUniform('FX1PulseIntensity', 0.2);
				edge.setUniform('FX1PulseClip', 0.2);
				edge.setUniform('FX1FlowAngle', -1.59);
				edge.setUniform('FX1FlowMapXOffset', 0.0);
				edge.setUniform('FX1FlowMapYOffset', 0.0);
				edge.setUniform('FX1FlowMapScrollXSpeed', 0.0);
				edge.setUniform('FX1FlowMapScrollYSpeed', -20);
				edge.setUniform('FX1FlowMapScaleXSpeed', 1);
				edge.setUniform('FX1FlowMapScaleYSpeed', 1);
				edge.setUniform('FX1ScaleX', 1);
				edge.setUniform('FX1ScaleY', 1);
				edge.setColor('baseColor', '#9ed1ff');
				edge.setColor('FX1Color', '#9ed1ff');

				edge.material.blending = THREE.CustomBlending;
				edge.material.blendEquation = THREE.AddEquation;
				edge.material.blendSrc = THREE.SrcAlphaFactor;
				edge.material.blendDst = THREE.OneMinusSrcAlphaFactor;

				edge.animateUniform('lineFade', 1.0, Math.random() * 2000 + 2000);

				var mesh = edge.getMesh();
				result.meshes.push(mesh);
				result.edges.push(edge);
			}
		}

		return result;
	}

	start() {
		this.animate();
	}

	animate(timestamp) {
		var deltaTime = timestamp - this.lastTimestamp;
		this.lastTimestamp = timestamp;
		requestAnimationFrame(this.animate.bind(this));

		this.controls.update();

		for (var i in this.nodes) {
			var node = this.nodes[i];
			node.animate(deltaTime);
			// node.update(this.mouse);
		}
		for (var i in this.edges) {
			var graph = this.graph;
			var edge = this.edges[i];
			var result = edge.animate(deltaTime);
			if (result) {
				// console.log(edge);
				// var node = edge.nodeB;
				// node.visited = true;
				// node.setColor('baseColor', '#ffffff');
				// var links = graph[node.name];

				// var result = this.processEdges(node, graph, this.shaders['alphablend_layer4']);

				// Array.prototype.push.apply(this.edges, result.edges);
				// // console.log(result);
				// this.addMeshesToScene(result.meshes, this.scene);

				// for (var target in links) {
				// 	var link = links[target];
				// 	var targetNode = link.node;
				// 	// console.log('>',targetNode);
				// 	targetNode.visible = true;
				// 	targetNode.animateUniform('lineFade', 1.0, Math.random() * 2000 + 1000);
				// 	// targetNode.setUniform('globalAlpha', 1.0);
				// }
			}
			// edge.update(this.mouse);
		}

		this.render();
	}

	render() {
		this.renderer.render(this.scene, this.camera);
	}

	onWindowResize() {
		this.resize();
		setTimeout(() => {
			this.resize();
		}, 500);
		setTimeout(() => {
			this.resize();
		}, 2000);
	}

	onOrientationChange() {
		// console.log('orientationchange');
		this.resize();
	}

	resize() {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0;

		document.body.scrollLeft = 0; // For Safari
		document.documentElement.scrollLeft = 0;

		var width  = window.innerWidth;
		var height = window.innerHeight;

		this.camera.aspect = width / height;
		this.camera.left = -width / 2;
		this.camera.right = width / 2;
		this.camera.top = height / 2;
		this.camera.bottom = -height / 2;
		this.camera.updateProjectionMatrix();

		// TODO: make it work even when canvas is smaller than window
		this.renderer.setSize(window.innerWidth, window.innerHeight);
	}

	onMouseMove(event) {
		var mx = event.clientX;
		var my = event.clientY;

		this.mouse.x = mx;
		this.mouse.y = my;
	}

	onTouchStart(event) {
		var pos = {
			x: 0,
			y: 0
		}

		if (event.touches.length > 0) {
			pos.x = event.touches[0].clientX;
			pos.y = event.touches[0].clientY;
		}
		else if (event.changedTouches.length > 0) {
			pos.x = event.changedTouches[0].clientX;
			pos.y = event.changedTouches[0].clientY;
		}

		this.startPos = pos;
	}

	onTouchEnd(event) {
		var pos = {
			x: 0,
			y: 0
		}

		if (event.touches.length > 0) {
			pos.x = event.touches[0].clientX;
			pos.y = event.touches[0].clientY;
		}
		else if (event.changedTouches.length > 0) {
			pos.x = event.changedTouches[0].clientX;
			pos.y = event.changedTouches[0].clientY;
		}

		var threshold = 50;
		var distance = this.distance(pos, this.startPos);

		if(distance < threshold) {
			this.handleClick(pos);
		}
	}

	distance(pA, pB) {
		return Math.sqrt(Math.pow(pB.x - pA.x, 2) + Math.pow(pB.y - pA.y, 2));
	}

	onClick(event) {
		event.preventDefault();

		var pos = {
			x: 0,
			y: 0
		}
		if (event.clientX !== undefined) {
			pos.x = event.clientX;
			pos.y = event.clientY;
		}

		this.handleClick(pos);
	}

	handleClick(pos) {
		var mouse = new THREE.Vector2((pos.x / window.innerWidth) * 2 - 1, - (pos.y / window.innerHeight) * 2 + 1);
		var intersections = this.intersect(mouse, this.camera, this.meshes);
		if (intersections.length > 0) {
			this.resolveIntersections(intersections, this.nodes, this.graph);
		}
	}

	intersect(mouse, camera, meshes) {
		this.raycaster.setFromCamera(mouse, camera);

		return this.raycaster.intersectObjects(meshes, true);
	}

	unselect(node) {
		if (node !== undefined) {
			this.removeMeshFromScene(node.getMesh(), this.scene);
			var mesh = node.buildMesh(node.name+'-default');
			node.setMesh(mesh);
			this.addMeshesToScene([mesh], this.scene);
			this.selection = undefined;
		}
	}

	select(node) {
		this.removeMeshFromScene(node.getMesh(), this.scene);
		this.updateDescription(node.name);
		var mesh = node.buildMesh(node.name+'-selected');
		node.setMesh(mesh);
		this.addMeshesToScene([mesh], this.scene);
		this.selection = node;
	}

	updateDescription(name) {
		var detailsWindow = document.getElementById('details');
		if (name in this.description) {
			detailsWindow.style.display = 'block';
			var title = document.getElementById('title');
			var experience = document.getElementById('experience');
			var description = document.getElementById('description');
			title.innerHTML = `&gt; ${name}`;
			experience.innerHTML = 'Experience: '+this.description[name].experience;
			description.innerHTML = this.description[name].description;
		}
		else {
			detailsWindow.style.display = 'none';
		}
	}

	onCloseDescriptionClick() {
		var detailsWindow = document.getElementById('details');
		detailsWindow.style.display = 'none';
		this.unselect(this.selection);
	}

	onCloseMailClick() {
		var mailWindow = document.getElementById('mail');
		mailWindow.style.display = 'none';
		var mailButton = document.getElementById('mailbutton');
		mailButton.style.display = 'block';
	}

	onOpenMailClick() {
		var mailWindow = document.getElementById('mail');
		mailWindow.style.display = 'block';
		var desc = "WW91IGNhbiByZWFjaCBtZSBhdCA8YSBjbGFzcz0ibWFpbC1saW5rIiBocmVmPSJtYWlsdG86bWVAZ2J1em9nYW55LmNvbSI+bWVAZ2J1em9nYW55LmNvbTwvYT4=";
		var descriptionField = mailWindow.children[1];
		descriptionField.innerHTML = atob(desc);
		var mailButton = document.getElementById('mailbutton');
		mailButton.style.display = 'none';
	}

	resolveIntersections(intersections, nodes, graph) {
		for (var i in intersections) {
			var node = intersections[i].object.userData['node'];
			if (this.selection != undefined) {
				this.unselect(this.selection);
			}
			this.select(node);
			if (node.visited == false && node.visible == true) {
				node.visited = true;
				node.setColor('baseColor', '#ffffff');
				var links = graph[node.name];

				var result = this.processEdges(node, graph, this.shaders['alphablend_layer4']);

				Array.prototype.push.apply(this.edges, result.edges);
				this.addMeshesToScene(result.meshes, this.scene);

				for (var target in links) {
					var link = links[target];
					var targetNode = link.node;
					targetNode.visible = true;
					targetNode.animateUniform('lineFade', 1.0, Math.random() * 2000);
					// targetNode.setUniform('globalAlpha', 1.0);
				}
			}
		}
	}
}

export default Canvas;